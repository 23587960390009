"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentRedirectScreen = void 0;
var React = require("react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var semantic_ui_react_1 = require("semantic-ui-react");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var session_storage_1 = require("../../libs/session.storage");
var state_repository_1 = require("../common/libs/state-repository");
var toast_util_1 = require("../shoprenter/errors/toast.util");
var router_provider_1 = require("@codebuild/uikit/libs/router/router.provider");
var barion_full_pixel_1 = require("../barion/barion-full-pixel");
var TOTAL_SECONDS_BEFORE_REDIRECT = 10;
var PaymentRedirectScreen = (function (_super) {
    __extends(PaymentRedirectScreen, _super);
    function PaymentRedirectScreen(props) {
        var _this = _super.call(this, props) || this;
        _this.shop = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            isLoading: true,
            transactionId: null,
            resultApiResponse: null,
            secondsBeforeAutomaticRedirect: TOTAL_SECONDS_BEFORE_REDIRECT,
        };
        _this.timerRef = React.createRef();
        return _this;
    }
    PaymentRedirectScreen.prototype.redirectToCreditCardPaymentSettingsTab = function () {
        router_provider_1.RouterProvider.replaceQuery({ tab: "creditCardPayments" });
        router_provider_1.RouterProvider.goTo("/settings");
    };
    PaymentRedirectScreen.prototype.sendBigfishResultRequest = function (transactionId) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function () {
            var url, response, product, purchaseProperties, error_1;
            var _this = this;
            return __generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        url = "/s/" + ((_a = session_storage_1.SessionStorage.getItem('SelectedShop')) === null || _a === void 0 ? void 0 : _a._id) + "/shop/bigfish/payment-result?transactionId=" + transactionId;
                        _f.label = 1;
                    case 1:
                        _f.trys.push([1, 3, 4, 5]);
                        this.setState({ isLoading: true });
                        return [4, this.shop.get(url, {})];
                    case 2:
                        response = _f.sent();
                        this.setState({ resultApiResponse: response });
                        product = __assign({}, barion_full_pixel_1.MONTHLY_SUBSCRIPTION_CONTENT_VIEW_PROPERTIES);
                        if ((_b = response === null || response === void 0 ? void 0 : response.Init) === null || _b === void 0 ? void 0 : _b.Amount) {
                            product.unitPrice = response.Init.Amount;
                        }
                        purchaseProperties = __assign({}, barion_full_pixel_1.PURCHASE_PROPERTIES);
                        if ((_c = response === null || response === void 0 ? void 0 : response.Init) === null || _c === void 0 ? void 0 : _c.Amount) {
                            purchaseProperties.contents = [product];
                            purchaseProperties.revenue = response.Init.Amount;
                            purchaseProperties.step = 2;
                        }
                        if (((_d = response === null || response === void 0 ? void 0 : response.Init) === null || _d === void 0 ? void 0 : _d.ResultCode) === "SUCCESSFUL" || ((_e = response === null || response === void 0 ? void 0 : response.Refund) === null || _e === void 0 ? void 0 : _e.ResultCode) === "SUCCESSFUL") {
                            barion_full_pixel_1.sendPurchaseMessage(purchaseProperties);
                            this.timerRef.current = setInterval(function () {
                                if (_this.state.secondsBeforeAutomaticRedirect === 0) {
                                    console.log('secondsBeforeAutomaticRedirect reached ZERO, redirect to payment settings page');
                                    if (_this.timerRef.current) {
                                        clearInterval(_this.timerRef.current);
                                        _this.timerRef.current = null;
                                    }
                                    _this.redirectToCreditCardPaymentSettingsTab();
                                }
                                else if (_this.state.secondsBeforeAutomaticRedirect > 0) {
                                    console.log('Timer:', _this.state.secondsBeforeAutomaticRedirect);
                                    _this.setState(function (prevState) { return ({
                                        secondsBeforeAutomaticRedirect: prevState.secondsBeforeAutomaticRedirect - 1
                                    }); });
                                }
                            }, 1000);
                        }
                        else {
                            purchaseProperties.step = -1;
                            barion_full_pixel_1.sendPurchaseMessage(purchaseProperties);
                        }
                        return [3, 5];
                    case 3:
                        error_1 = _f.sent();
                        toast_util_1.openErrorToastWithMessage("Error from URL=" + url);
                        barion_full_pixel_1.sendErrorMessage("Cannot fetch payment info on redirect page");
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    PaymentRedirectScreen.prototype.componentDidMount = function () {
        var _a, _b, _c;
        this.fetchShop();
        console.log("PaymentRedirectScreen componentDidMount transactionId = " + this.props.route.query);
        if (((_a = this.props.route.query) === null || _a === void 0 ? void 0 : _a.TransactionId) || ((_b = this.props.route.query) === null || _b === void 0 ? void 0 : _b.transactionId)) {
            var transactionId = ((_c = this.props.route.query) === null || _c === void 0 ? void 0 : _c.TransactionId) || this.props.route.query.transactionId;
            this.setState({ transactionId: transactionId });
            this.sendBigfishResultRequest(transactionId);
        }
    };
    PaymentRedirectScreen.prototype.componentWillUnmount = function () {
        if (this.timerRef.current) {
            clearInterval(this.timerRef.current);
        }
    };
    PaymentRedirectScreen.prototype.fetchShop = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.shop.get("/a/shop/" + ((_a = session_storage_1.SessionStorage.getItem('SelectedShop')) === null || _a === void 0 ? void 0 : _a._id), {})];
                    case 1:
                        _b.sent();
                        return [2];
                }
            });
        });
    };
    PaymentRedirectScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var timerProgress = Math.floor(((TOTAL_SECONDS_BEFORE_REDIRECT - this.state.secondsBeforeAutomaticRedirect) / TOTAL_SECONDS_BEFORE_REDIRECT) * 100);
        return React.createElement("div", { className: "SettingsScreen display-flex flex-row" },
            React.createElement("div", { className: "flex-fill SettingsContentWrapper" },
                React.createElement(semantic_ui_react_1.Header, { as: 'h1' }, "BigfishPaymentRedirectScreen"),
                React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Fizet\u00E9si adatok"),
                React.createElement("p", null,
                    "Tranzakci\u00F3s azonos\u00EDt\u00F3: ",
                    React.createElement("b", null, this.state.transactionId)),
                ((_a = this.state.resultApiResponse) === null || _a === void 0 ? void 0 : _a.Init) && (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "Fizet\u00E9s \u00F6sszege: ",
                        React.createElement("b", null, (_c = (_b = this.state.resultApiResponse) === null || _b === void 0 ? void 0 : _b.Init) === null || _c === void 0 ? void 0 :
                            _c.Amount,
                            " ", (_e = (_d = this.state.resultApiResponse) === null || _d === void 0 ? void 0 : _d.Init) === null || _e === void 0 ? void 0 :
                            _e.Currency)))),
                ((_f = this.state.resultApiResponse) === null || _f === void 0 ? void 0 : _f.Result) && (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "Fizet\u00E9s \u00E1llapota: ",
                        React.createElement("b", null, (_h = (_g = this.state.resultApiResponse) === null || _g === void 0 ? void 0 : _g.Result) === null || _h === void 0 ? void 0 :
                            _h.ResultCode,
                            " - ", (_k = (_j = this.state.resultApiResponse) === null || _j === void 0 ? void 0 : _j.Result) === null || _k === void 0 ? void 0 :
                            _k.ResultMessage)))),
                this.state.isLoading ? (React.createElement("div", { className: 'ListLoader' },
                    React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' }, "Adatok bet\u00F6lt\u00E9se"))) : (React.createElement("div", null, this.state.resultApiResponse && (React.createElement("div", null,
                    React.createElement(semantic_ui_react_1.Grid, { className: 'my-4' },
                        React.createElement(semantic_ui_react_1.Grid.Row, null,
                            React.createElement(semantic_ui_react_1.Grid.Column, { width: 16 },
                                React.createElement(semantic_ui_react_1.Progress, { percent: timerProgress, indicating: true },
                                    "\u00C1tir\u00E1ny\u00EDt\u00E1s ",
                                    this.state.secondsBeforeAutomaticRedirect,
                                    " m\u00E1sodperc m\u00FAlva..."))),
                        React.createElement(semantic_ui_react_1.Grid.Row, null,
                            React.createElement(semantic_ui_react_1.Grid.Column, { width: 16, textAlign: 'center' },
                                React.createElement(semantic_ui_react_1.Button, { color: "blue", onClick: function () { return _this.redirectToCreditCardPaymentSettingsTab(); } }, "Azonnali \u00E1tir\u00E1ny\u00EDt\u00E1s"))))))))));
    };
    return PaymentRedirectScreen;
}(abstract_component_1.AbstractComponent));
exports.PaymentRedirectScreen = PaymentRedirectScreen;
