"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminCreditCardPayments = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var shop_payment_events_component_1 = require("../../../common/components/payments/shop-payment-events.component");
var environment_1 = require("@codebuild/uikit/libs/environment");
var invoices_with_populated_payments_component_1 = require("../../../settings/components/billing/invoices-with-populated-payments.component");
var AdminCreditCardPayments = (function (_super) {
    __extends(AdminCreditCardPayments, _super);
    function AdminCreditCardPayments() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.shop = repository_1.Repository.use('default');
        _this.state = {
            shop: _this.props.shop,
            loading: false,
            isCreditCardEnabled: _this.props.shop.creditCardEnabled,
            showConfirmationModal: false,
        };
        return _this;
    }
    AdminCreditCardPayments.prototype.getCurrentShop = function () {
        return session_storage_1.SessionStorage.getItem('SelectedShop');
    };
    AdminCreditCardPayments.prototype.saveCreditCardConfirmation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var baseUrl, url, updatedShop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ loading: true });
                        baseUrl = environment_1.Environment.get('api');
                        url = baseUrl + "/s/" + this.state.shop._id.toString() + "/shop";
                        return [4, this.shop.put(url, {
                                data: {
                                    creditCardEnabled: !this.state.isCreditCardEnabled,
                                }
                            })];
                    case 1:
                        updatedShop = _a.sent();
                        toast_util_1.openSuccessToast('A választásod mentésre került!');
                        this.setState(function (prevState) { return ({
                            loading: false,
                            isCreditCardEnabled: updatedShop.creditCardEnabled,
                            showConfirmationModal: false
                        }); });
                        this.setState({ loading: false });
                        if (this.shopPaymentEventsRef) {
                            this.shopPaymentEventsRef.fetchPaymentEventsByShopId();
                        }
                        return [2];
                }
            });
        });
    };
    AdminCreditCardPayments.prototype.renderCreditCardPayments = function () {
        return React.createElement(invoices_with_populated_payments_component_1.InvoicesWithPopulatedPayments, { shopId: this.state.shop._id.toString() });
    };
    AdminCreditCardPayments.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'BillingComponent' },
            React.createElement(semantic_ui_react_1.Grid, { className: 'mt-4' },
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h2' }, "Bankk\u00E1rty\u00E1s fizet\u00E9s"))),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        "Bankk\u00E1rty\u00E1s fizet\u00E9s st\u00E1tusza:",
                        React.createElement("br", null),
                        this.state.isCreditCardEnabled ? (React.createElement(React.Fragment, null,
                            React.createElement(semantic_ui_react_1.Icon, { color: "green", name: "check circle", size: "large" }),
                            "Enged\u00E9lyezve.")) : (React.createElement(React.Fragment, null,
                            React.createElement(semantic_ui_react_1.Icon, { color: "yellow", name: "exclamation circle", size: "large" }),
                            "Kikapcsolva.")),
                        React.createElement("br", null),
                        React.createElement(semantic_ui_react_1.Button, { className: 'mt-4', disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.setState({ showConfirmationModal: true }); }, content: "Bankk\u00E1rty\u00E1s fizet\u00E9s " + (this.state.isCreditCardEnabled ? 'letiltása' : 'bekapcsolása') }),
                        React.createElement(semantic_ui_react_1.Modal, { onClose: function () { return _this.setState({ showConfirmationModal: false }); }, open: this.state.showConfirmationModal },
                            React.createElement(semantic_ui_react_1.ModalHeader, null, "Bankk\u00E1rty\u00E1s fizet\u00E9s"),
                            React.createElement(semantic_ui_react_1.ModalContent, null,
                                React.createElement(semantic_ui_react_1.ModalDescription, null,
                                    React.createElement(semantic_ui_react_1.Header, null,
                                        "Biztosan szeretn\u00E9d ",
                                        React.createElement("strong", null, this.state.isCreditCardEnabled ? 'kikapcsolni' : 'bekapcsolni'),
                                        " a bankk\u00E1rty\u00E1s fizet\u00E9st?"))),
                            React.createElement(semantic_ui_react_1.ModalActions, null,
                                React.createElement(semantic_ui_react_1.Button, { color: "red", onClick: function () { return _this.setState({ showConfirmationModal: false }); } }, "M\u00E9gse"),
                                React.createElement(semantic_ui_react_1.Button, { content: "Meger\u0151s\u00EDtem a d\u00F6nt\u00E9semet!", color: "green", disabled: this.state.loading, loading: this.state.loading, onClick: function () { return _this.saveCreditCardConfirmation(); } }))),
                        React.createElement("br", null),
                        React.createElement(semantic_ui_react_1.Header, { as: 'h3', className: 'mt-4' }, "El\u0151zm\u00E9nyek"),
                        React.createElement(shop_payment_events_component_1.ShopPaymentEvents, { shopId: this.state.shop._id.toString(), ref: function (ref) { return _this.shopPaymentEventsRef = ref; } }))),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        React.createElement(semantic_ui_react_1.Header, { as: 'h3' }, "Sz\u00E1ml\u00E1k, bankk\u00E1rty\u00E1s fizet\u00E9sek"))),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 16 },
                        React.createElement(semantic_ui_react_1.Table, { celled: true, structured: true },
                            React.createElement(semantic_ui_react_1.TableHeader, null,
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { colSpan: "4", textAlign: "center" }, "Sz\u00E1mla"),
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { colSpan: "3", textAlign: "center" }, "Bankk\u00E1rty\u00E1s fizet\u00E9sek")),
                                React.createElement(semantic_ui_react_1.TableRow, null,
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: "center" }, "Id\u0151pont"),
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: "center" }, "Tartalom"),
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: "center" }, "\u00C1llapot"),
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: "center" }, "Let\u00F6lt\u00E9s"),
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: "center" }, "Id\u0151pont"),
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: "center" }, "St\u00E1tusz"),
                                    React.createElement(semantic_ui_react_1.TableHeaderCell, { textAlign: "center" }, "Megjegyz\u00E9s"))),
                            React.createElement(semantic_ui_react_1.TableBody, null, this.renderCreditCardPayments()))))));
    };
    return AdminCreditCardPayments;
}(abstract_component_1.AbstractComponent));
exports.AdminCreditCardPayments = AdminCreditCardPayments;
