"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopProfileComponent = void 0;
var field_1 = require("@codebuild/uikit/libs/form/field");
var form_builder_1 = require("@codebuild/uikit/libs/form/form-builder");
var form_control_1 = require("@codebuild/uikit/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var trans_1 = require("@codebuild/uikit/libs/translation/trans");
var semantic_ui_input_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.input.form-control-type");
var semantic_ui_select_form_control_type_1 = require("@codebuild/uikit/modules/semantic-ui/form/form-control/types/semantic-ui.select.form-control-type");
var lodash_1 = require("lodash");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var session_storage_1 = require("../../../../libs/session.storage");
var detailed_error_1 = require("../../../common/components/errors/detailed-error");
var url_validator_1 = require("../../../common/components/url-validator/url-validator");
var handle_toasts_1 = require("../../../common/libs/handle.toasts");
var industry_options_1 = require("../../../common/libs/industry-options");
var state_repository_1 = require("../../../common/libs/state-repository");
var color_picker_1 = require("../../../common/semantic/color-picker/color-picker");
var image_uploader_1 = require("../../../common/semantic/image-uploader/image-uploader");
var ShopProfileComponent = (function (_super) {
    __extends(ShopProfileComponent, _super);
    function ShopProfileComponent() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var _this = _super.apply(this, arguments) || this;
        _this.shopRepository = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'shop');
        _this.state = {
            shop: session_storage_1.SessionStorage.getItem('SelectedShop'),
            updateLoading: false,
            updateError: false,
        };
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                title: new field_1.Field({
                    name: 'title',
                    placeholder: '',
                    label: '',
                    value: ((_b = (_a = _this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b.title) || '',
                    validators: []
                }),
                email: new field_1.Field({
                    name: 'email',
                    placeholder: '',
                    label: '',
                    value: ((_d = (_c = _this.state) === null || _c === void 0 ? void 0 : _c.shop) === null || _d === void 0 ? void 0 : _d.email) || '',
                    validators: []
                }),
                phone: new field_1.Field({
                    name: 'phone',
                    placeholder: '',
                    label: '',
                    value: ((_f = (_e = _this.state) === null || _e === void 0 ? void 0 : _e.shop) === null || _f === void 0 ? void 0 : _f.phone) || '',
                    validators: []
                }),
                url: new field_1.Field({
                    name: 'url',
                    placeholder: '',
                    label: '',
                    value: ((_h = (_g = _this.state) === null || _g === void 0 ? void 0 : _g.shop) === null || _h === void 0 ? void 0 : _h.url) || '',
                    validators: [
                        new url_validator_1.UrlValidator('invalid-url')
                    ]
                }),
                industry: new field_1.Field({
                    name: 'industry',
                    placeholder: '',
                    label: '',
                    value: ((_k = (_j = _this.state) === null || _j === void 0 ? void 0 : _j.shop) === null || _k === void 0 ? void 0 : _k.industry) || '',
                    options: industry_options_1.industryOptions.map(function (i) { return (__assign(__assign({}, i), { text: trans_1.trans(i.text) })); }),
                    validators: []
                }),
                themeColor: new field_1.Field({
                    name: 'themeColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.themeColor || '',
                    validators: []
                }),
                buttonTextColor: new field_1.Field({
                    name: 'buttonTextColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.buttonTextColor || '',
                    validators: []
                }),
                buttonBackgroundColor: new field_1.Field({
                    name: 'buttonBackgroundColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.buttonBackgroundColor || '',
                    validators: []
                }),
                headingTextColor: new field_1.Field({
                    name: 'headingTextColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.headingTextColor || '',
                    validators: []
                }),
                headingBackgroundColor: new field_1.Field({
                    name: 'headingBackgroundColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.headingBackgroundColor || '',
                    validators: []
                }),
                textTextColor: new field_1.Field({
                    name: 'textTextColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.textTextColor || '',
                    validators: []
                }),
                headerBackgroundColor: new field_1.Field({
                    name: 'headerBackgroundColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.headerBackgroundColor || '',
                    validators: []
                }),
                headerTextColor: new field_1.Field({
                    name: 'headerTextColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.headerTextColor || '',
                    validators: []
                }),
                footerBackgroundColor: new field_1.Field({
                    name: 'footerBackgroundColor',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.footerBackgroundColor || '',
                    validators: []
                }),
                logo: new field_1.Field({
                    name: 'logo',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.logo || '',
                    validators: []
                }),
                bannerImage: new field_1.Field({
                    name: 'bannerImage',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.bannerImage || '',
                    validators: []
                }),
                bannerLink: new field_1.Field({
                    name: 'bannerLink',
                    placeholder: '',
                    label: '',
                    value: _this.state.shop.bannerLink || '',
                    validators: []
                }),
            }
        });
        return _this;
    }
    ShopProfileComponent.prototype.componentDidMount = function () {
        var _this = this;
        this.$subscriptions.push(this.form.$submitSuccess.subscribe(function () { return _this.handleSubmit(); }));
    };
    ShopProfileComponent.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        return __awaiter(this, void 0, void 0, function () {
            var shop;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shop = session_storage_1.SessionStorage.getItem('SelectedShop');
                        if (!!lodash_1.isEqual(prevState.shop, shop)) return [3, 2];
                        return [4, this.setState({ shop: shop })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ShopProfileComponent.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'ShopProfileComponent' },
            React.createElement(semantic_ui_react_1.Grid, { as: semantic_ui_react_1.Form, className: 'pl-4', onSubmit: function () { return _this.form.submit(); } },
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h4' }, trans_1.trans('settings.profile.shop-profile.title')))),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, null,
                        React.createElement(semantic_ui_react_1.Header, { as: 'h5' }, trans_1.trans('settings.profile.shop-profile.data.subtitle')))),
                React.createElement(semantic_ui_react_1.Divider, { className: 'my-0' }),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Web\u00E1ruh\u00E1z neve"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.title, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Web\u00E1ruh\u00E1z emailc\u00EDme"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.email, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false, type: "email", pattern: "[a-z0-9._%+\\-]+@[a-z0-9.\\-]+\\.[a-z]{2,}$" })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Web\u00E1ruh\u00E1z telefonsz\u00E1ma"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.phone, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false, type: "tel" })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Domain"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.url, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: function (props) { return (React.createElement(semantic_ui_react_1.Input, __assign({}, props))); }, fluid: true, disabled: true })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Web\u00E1ruh\u00E1z ipar\u00E1ga"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.industry, type: semantic_ui_select_form_control_type_1.SemanticUiSelectFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Select, fluid: true, disabled: false })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Web\u00E1ruh\u00E1z log\u00F3"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.logo, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: image_uploader_1.ImageUploader.withProps({
                                    buttonTitle: 'Webáruház logó feltöltése',
                                    maxWidth: 220,
                                    maxHeight: 90
                                }), fluid: true })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Email Banner"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.bannerImage, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: image_uploader_1.ImageUploader.withProps({ buttonTitle: 'Email Banner feltöltése' }), fluid: true })),
                        React.createElement("span", null, "Banner k\u00E9p aj\u00E1nlott m\u00E9rete: 600x300 pixel, vagy 600x150 pixel"))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Email Banner Link"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.bannerLink, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: semantic_ui_react_1.Input, fluid: true, disabled: false })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "T\u00E9ma sz\u00EDne"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.themeColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Gomb sz\u00F6vegsz\u00EDne"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonTextColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Gomb h\u00E1tt\u00E9rsz\u00EDne"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.buttonBackgroundColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "C\u00EDmsor sz\u00F6vegsz\u00EDne"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.headingTextColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "C\u00EDmsor h\u00E1tt\u00E9rsz\u00EDne"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.headingBackgroundColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Sz\u00F6vegsz\u00EDn"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.textTextColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Fejl\u00E9c h\u00E1tt\u00E9rsz\u00EDne"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.headerBackgroundColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 2 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 }, "Fejl\u00E9c sz\u00F6vegsz\u00EDne"),
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 6 },
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.headerTextColor, type: semantic_ui_input_form_control_type_1.SemanticUiInputFormControlType },
                            React.createElement(semantic_ui_react_1.Form.Field, { control: color_picker_1.ColorPicker, fluid: true })))),
                React.createElement(semantic_ui_react_1.Divider, { className: 'my-0' }),
                React.createElement(semantic_ui_react_1.Grid.Row, null,
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 8 },
                        React.createElement(detailed_error_1.DetailedError, { error: this.state.updateError }))),
                React.createElement(semantic_ui_react_1.Grid.Row, { columns: 1 },
                    React.createElement(semantic_ui_react_1.Grid.Column, { width: 2 },
                        React.createElement(semantic_ui_react_1.Button, { loading: this.state.updateLoading, disabled: this.state.updateLoading, content: 'Mentés', type: "submit" })))));
    };
    ShopProfileComponent.prototype.getPayload = function () {
        var data = this.form.toJSON();
        return __assign(__assign({}, this.state.shop), data);
    };
    ShopProfileComponent.prototype.handleSubmit = function () {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var payload, result, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.setState({ updateLoading: true, updateError: false });
                        payload = this.getPayload();
                        return [4, this.shopRepository.put("/s/" + ((_b = (_a = this.state) === null || _a === void 0 ? void 0 : _a.shop) === null || _b === void 0 ? void 0 : _b._id) + "/shop", { data: payload })];
                    case 1:
                        result = _c.sent();
                        this.setState({ updateLoading: false });
                        handle_toasts_1.HandleToasts.success(trans_1.trans('setting.profile.update.success'));
                        session_storage_1.SessionStorage.setItem('SelectedShop', result);
                        return [3, 3];
                    case 2:
                        e_1 = _c.sent();
                        this.setState({ updateLoading: false, updateError: e_1 });
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    return ShopProfileComponent;
}(abstract_component_1.AbstractComponent));
exports.ShopProfileComponent = ShopProfileComponent;
